import React from 'react';
import Fade from 'react-reveal/Fade';
import {StaticImage} from 'gatsby-plugin-image';
import {ReviewContent} from './styled';

const Reviews = () => {
  return (
    <ReviewContent>
      <div className="review-head">
        <h1 className='my-10 font-bold'>
          Discover how our innovative solutions have transformed businesses.
        </h1>
      </div>
      <div className="reviews">
        <div>
          <h4 className='my-4 font-bold text-lg'>Gezzin</h4>
          <p>
            Zegates International's well-allocated resources and efficiently
            managed tasks have resulted in the timely release of updates. They
            go the extra mile to achieve the overall company's goals and vision.
          </p>

          <div>
            <img src="/images/users/rithik_illankovan.jpg" />
            <div>
              <p>Rithik Illankovan</p>
              <p>Director, Gezzin LTD</p>
            </div>
          </div>
        </div>
      </div>
    </ReviewContent>
  );
};

export default Reviews;
