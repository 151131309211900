import styled from 'styled-components';

export const ReviewContent = styled.div`
  background: ${({theme}) => theme.palatte.background.light};
  padding: 2em 0;
  height: 100%;

  & > .review-head {
    border-top: 1px solid ${({theme}) => theme.palatte.text.default};
    border-bottom: 1px solid ${({theme}) => theme.palatte.text.default};
    max-width: 1000px;
    width: 100%;
    margin: 2em auto;

    & > h1 {
      max-width: 750px;
      line-height: 1.5;
      font-family: Thicccboi, sans-serif;
      font-size: 3em;
    }
  }

  & > .reviews {
    display: flex;
    max-width: 1000px;
    width: 100%;
    height: 470px;
    margin: 4em auto 0px auto;
    background: #fff;
    padding: 2em;
    gap: 1em;

    & > div {
      max-width: 300px;
      max-height: 300px;
      font-family: Thicccboi, sans-serif;
      line-height: 1.5;

      & > h4 {
        opacity: 0.6;
      }
      & > p {
        font-weight: 600;
        font-size: 1.25em;
        height: 100%;
      }

      & > div {
        display: flex;
        align-items: center;
        gap: 0.6em;

        & > img {
          width: 54px;
          height: 54px;
          border-radius: 54px;
        }

        & > div {
          display: flex;
          flex-direction: column;
        }
        & > div > p {
          opacity: 0.6;
          margin: 0px;
          font-weight: 600;
          max-width: 160px;
          line-height: 1.5;
        }
        & > div > p:last-child {
        font-size: 13px;
        }
      }
    }
  }
`;
