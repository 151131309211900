import * as React from 'react';
import Layout from '../components/Layout';
import Slider from '../components/Slider';
import Steps from '../components/Steps';
// import Services from '../components/Services';
// import ServiceIndividual from '../components/Services/ServiceIndividual';
// import ServiceSelect from '../components/Services/ServiceSelect';
// import Product from '../components/Product';
import Footer from '../components/Footer';
// import Work from '../components/Work';
import ContactForm from '../modules/contact/ContactForm';
import HomeHeader from '../components/Header/HomeHeader';
import ServiceProjects from '../components/Services/ServiceProjects';
import ServicePerformance from '../components/Services/ServicePerformance';
import Reviews from '../components/Reviews/Reviews';

const IndexPage = () => {
  return (
    <Layout>
      <HomeHeader />
      <Slider />
      <ContactForm />
      <ServiceProjects />
      <Steps />
      <ServicePerformance />
      <Reviews />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
